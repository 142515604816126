import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';

const {
  getUserWidgets,
  postUserWidget,
  putUserWidget,
  deleteUserWidget,
  getNonCorrelativeInvoices,
  getFinalAndOpenedInvoices,
  getInvoicesWithoutNif,
  getOpenedInvoicesWithFinishBookings,
  getBookingInvoiceKmsMismatch,
  getInvoicesWithChargeBack,
  getSapExportVsInvoices,
  getSignUps,
  getBookingsInAlert,
  getNotConfirmedBookings,
} = backOffice.widgets;

export const DASHBOARD_SCOPES = {
  userWidgets: 'userWidgets',
  nonCorrelativeInvoices: 'nonCorrelativeInvoices',
  finalAndOpenedInvoices: 'finalAndOpenedInvoices',
  invoicesWithoutNif: 'invoicesWithoutNif',
  openedInvoicesWithFinishedBookings: 'openedInvoicesWithFinishedBookings',
  invoicesKmsMismatch: 'invoicesKmsMismatch',
  invoicesWithChargeBack: 'invoicesWithChargeBack',
  sapExport: 'sapExport',
  signUps: 'signUps',
  bookingAlerts: 'bookingAlerts',
  notConfirmedBookings: 'notConfirmedBookings',
  addUserWidget: 'addUserWidget',
  editUserWidget: 'editUserWidget',
  deleteUserWidget: 'deleteUserWidget',
};

const dashboard = createCustomStore(({ runAsyncFlow }) => withScopes(DASHBOARD_SCOPES, ({
  actions: {
    async getUserWidgets({ commit }) {
      await runAsyncFlow(commit, {
        request: getUserWidgets,
        scope: DASHBOARD_SCOPES.userWidgets,
      });
    },
    async postUserWidget({ commit }, { userId, widgetId }) {
      await runAsyncFlow(commit, {
        request: postUserWidget,
        params: [userId, widgetId],
        scope: DASHBOARD_SCOPES.addUserWidget,
      });
    },
    async putUserWidget({ commit }, { userId, widgetId, data }) {
      await runAsyncFlow(commit, {
        request: putUserWidget,
        params: [userId, widgetId, data],
        scope: DASHBOARD_SCOPES.editUserWidget,
      });
    },
    async deleteUserWidget({ commit }, { userId, widgetId }) {
      await runAsyncFlow(commit, {
        request: deleteUserWidget,
        params: [userId, widgetId],
        scope: DASHBOARD_SCOPES.deleteUserWidget,
      });
    },
    async getNonCorrelativeInvoices({ commit }, operatorId) {
      await runAsyncFlow(commit, {
        request: getNonCorrelativeInvoices,
        params: [operatorId],
        scope: DASHBOARD_SCOPES.nonCorrelativeInvoices,
      });
    },
    async getFinalAndOpenedInvoices({ commit }, operatorId) {
      await runAsyncFlow(commit, {
        request: getFinalAndOpenedInvoices,
        params: [operatorId],
        scope: DASHBOARD_SCOPES.finalAndOpenedInvoices,
      });
    },
    async getInvoicesWithoutNif({ commit }, operatorId) {
      await runAsyncFlow(commit, {
        request: getInvoicesWithoutNif,
        params: [operatorId],
        scope: DASHBOARD_SCOPES.invoicesWithoutNif,
      });
    },
    async getOpenedInvoicesWithFinishBookings({ commit }, operatorId) {
      await runAsyncFlow(commit, {
        request: getOpenedInvoicesWithFinishBookings,
        params: [operatorId],
        scope: DASHBOARD_SCOPES.openedInvoicesWithFinishedBookings,
      });
    },
    async getBookingInvoiceKmsMismatch({ commit }, { operatorId, data }) {
      await runAsyncFlow(commit, {
        request: getBookingInvoiceKmsMismatch,
        params: [operatorId, data],
        scope: DASHBOARD_SCOPES.invoicesKmsMismatch,
      });
    },
    async getInvoicesWithChargeBack({ commit }, operatorId) {
      await runAsyncFlow(commit, {
        request: getInvoicesWithChargeBack,
        params: [operatorId],
        scope: DASHBOARD_SCOPES.invoicesWithChargeBack,
      });
    },
    async getSapExportVsInvoices({ commit }, operatorId) {
      await runAsyncFlow(commit, {
        request: getSapExportVsInvoices,
        params: [operatorId],
        scope: DASHBOARD_SCOPES.sapExport,
      });
    },
    async getSignUps({ commit }, operatorId) {
      await runAsyncFlow(commit, {
        request: getSignUps,
        params: [operatorId],
        scope: DASHBOARD_SCOPES.signUps,
      });
    },
    async getBookingsInAlert({ commit }) {
      await runAsyncFlow(commit, {
        request: getBookingsInAlert,
        params: [],
        scope: DASHBOARD_SCOPES.bookingAlerts,
      });
    },
    async getNotConfirmedBookings({ commit }) {
      await runAsyncFlow(commit, {
        request: getNotConfirmedBookings,
        params: [],
        scope: DASHBOARD_SCOPES.notConfirmedBookings,
      });
    },
  },
})));

export default dashboard;
